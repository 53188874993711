<template>
  <v-container class="py-0">
    <v-card color="secondary" dark>
      <v-card-title class="font-weight-medium">
        Cadastrar Localização da Empresa
      </v-card-title>
    </v-card>
    <v-form v-model="valid" ref="form">
      <v-row>
        <v-col>
          <v-text-field
            label="Nome da Empresa"
            v-model="form.name"
            required
            :rules="rules.name"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-card>
      <div id="map"></div>
    </v-card>
    <v-btn color="primary" class="mt-2" block @click="saveEnterprise"
      >Salvar</v-btn
    >
    <v-snackbar v-model="show" color="success" top centered>
      <v-row class="text-center" dense align="center" align-content="center">
        <v-col>
          <span class="font-weight-bold text-uppercase"
            >Cadastro realizado com sucesso!</span
          >
        </v-col>
      </v-row>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { GeometryService } from '@/services'
import {
  MODULE_NAME as ENTERPRISE,
  GET_ENTERPRISE,
  FETCH_ENTERPRISE
} from '@/store/enterprise'
import { MODULE_NAME as POSITION, GET_POSITION } from '@/store/getPosition'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'leaflet'
import 'leaflet-draw'
const LAYER_URL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
import { MODULE_NAME as AUTH, GET_CLIENT_ID } from '@/store/auth'
import { KEY } from '../store/enterprise'
export default {
  name: 'EmpresaView',
  data: () => ({
    map: null,
    drawControl: {},
    editControl: {},
    form: {
      name: null,
      geojson: null,
      type: null
    },
    rules: {
      name: [
        v => (v || '').length > 0 || 'Campo Obrigatório!',
        v => (v || '').length < 100 || 'Limite de caracteres atingido!'
      ]
    },
    drawnItems: null,
    valid: false,
    geometry: null,
    show: false,
    circleIsOnMap: false,
    LAYER_URL
  }),
  computed: {
    ...mapGetters(AUTH, {
      clientId: GET_CLIENT_ID
    }),
    ...mapGetters(ENTERPRISE, { enterprise: GET_ENTERPRISE })
  },
  watch: {
    enterprise: {
      handler(newValue) {
        if (newValue?.id && !this.circleIsOnMap) {
          const { coordinates } = newValue.geojson.geometry
          const { radius } = newValue.geojson.properties
          this.form.name = newValue.name
          this.form.id = newValue.id
          const circle = L.circle(coordinates.reverse(), {
            radius
          }).addTo(this.map)
          this.drawnItems.addLayer(circle)
          this.map.addLayer(circle)
          this.map.fitBounds(circle.getBounds())
          this.circleIsOnMap = true
          this.editControl.addTo(this.map)
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions(ENTERPRISE, { fetchEnterprise: FETCH_ENTERPRISE }),
    ...mapActions(POSITION, { getPosition: GET_POSITION }),
    initMap: function() {
      //? Inicialização do mapa
      this.map = L.map('map').setView([-17.553143, -49.295887], 3)

      L.tileLayer(LAYER_URL, {
        maxZoom: 19,
        minZoom: 3,
        attribution: '© OpenStreetMap'
      }).addTo(this.map)
      this.drawnItems = new L.FeatureGroup()

      this.map.addLayer(this.drawnItems)
      //? Controles do mapa para poder desenhar o círculo
      const enterprise = JSON.parse(localStorage.getItem(KEY))
      if (!enterprise?.id) {
        this.drawControl = new L.Control.Draw({
          draw: {
            circlemarker: false,
            polygon: false,
            polyline: false,
            circle: true,
            rectangle: false,
            marker: false
          },
          edit: {
            featureGroup: this.drawnItems,
            remove: false,
            edit: false
          }
        })
        this.map.addControl(this.drawControl)
      }

      //? Controles do mapa para poder editar o círculo

      this.editControl = new L.Control.Draw({
        edit: {
          featureGroup: this.drawnItems,
          remove: false
        },
        draw: false
      })

      //? Textos exibidos no mapa
      L.drawLocal = {
        draw: {
          toolbar: {
            actions: {
              title: 'Cancelar ',
              text: 'Cancelar'
            },
            finish: {
              title: '- your text-',
              text: '- yoAWDWADur text-'
            },
            undo: {
              title: '- your text-',
              text: '- AAtext-'
            },
            buttons: {
              polyline: '- your text-',
              polygon: '- your text-',
              rectangle: '- your text-',
              circle: 'Colocar um círculo',
              marker: 'Colocar um marcador',
              circlemarker: '- your text-'
            }
          },
          handlers: {
            circle: {
              tooltip: {
                start: 'Clique e arraste para desenhar um círculo'
              },
              radius: 'Raio'
            },
            circlemarker: {
              tooltip: {
                start: '- your text-.'
              }
            },
            marker: {
              tooltip: {
                start: 'Selecione o local da empresa!'
              }
            },
            polygon: {
              tooltip: {
                start: '- your text-.',
                cont: '- your text-.',
                end: '- your text-.'
              }
            },
            polyline: {
              error: '<strong>Error:</strong> shape edges cannot cross!',
              tooltip: {
                start: 'Click to start drawing line.',
                cont: 'Click to continue drawing line.',
                end: 'Click last point to finish line.'
              }
            },
            rectangle: {
              tooltip: {
                start: '- your text-.'
              }
            },
            simpleshape: {
              tooltip: {
                end: 'Solte o mouse para finalizar'
              }
            }
          }
        },
        edit: {
          toolbar: {
            actions: {
              save: {
                title: 'Salvar alterações',
                text: 'Salvar'
              },
              cancel: {
                title: 'Cancelar edição, descartar todas as alterações',
                text: 'Cancelar'
              },
              clearAll: {
                title: 'Clear all layers',
                text: 'Clear All'
              }
            },
            buttons: {
              edit: 'Editar',
              editDisabled: 'No layers to edit',
              remove: 'Delete layers',
              removeDisabled: 'No layers to delete'
            }
          },
          handlers: {
            edit: {
              tooltip: {
                text: 'Arraste o ponto central para alterar sua posição.',
                subtext: 'Clique em cancelar para desfazer as alterações.'
              }
            },
            remove: {
              tooltip: {
                text: 'Click on a feature to remove.'
              }
            }
          }
        }
      }

      //? Adicionando a feature ao mapa e transformando os valores em GEOJSON e repassando para o formulário
      this.map.on(L.Draw.Event.CREATED, e => {
        const layer = e.layer
        this.map.addLayer(layer)
        this.drawnItems.addLayer(layer)
        this.form.geojson = layer.toGeoJSON()
        this.form.geojson.properties = { radius: layer.options.radius }
        this.form.geojson = { ...this.form.geojson, company: true }
        this.form.type = this.form.geojson.geometry.type
        this.drawControl.remove()
        this.editControl.addTo(this.map)
      })

      //? Alterando os dados do formulário quando a feature for editada
      this.map.on(L.Draw.Event.EDITED, e => {
        const layers = e.layers
        layers.eachLayer(layer => {
          const _layer = layer.toGeoJSON()
          if (layer.getRadius) {
            _layer.properties = {
              radius: layer.getRadius()
            }
          }
          this.form.geojson = { ..._layer, company: true }
          this.form.type = this.form.geojson.geometry.type
        })
      })
    },
    async saveEnterprise() {
      if (!this.valid) {
        this.$refs.form.validate()
        return
      }
      await this.geometry.registerGeometry({
        ...this.form,
        client_id: this.clientId
      })
      // this.getPosition()
      this.show = true
    }
  },
  mounted() {
    this.initMap()
    this.fetchEnterprise()
    this.geometry = GeometryService(this.$store)
  }
}
</script>
<style scoped>
#map {
  height: 54vh;
  z-index: 0;
}
</style>
