// import { booleanContains, circle, point } from '@turf/turf'
// import { isPointWithinRadius } from 'geolib'
// import {
//   FETCH_ENTERPRISE,
//   KEY,
//   MODULE_NAME as ENTERPRISE
// } from '../store/enterprise'
// import { KEY_STORE } from './vehicles/constants'
// import { http } from '../services/http'

export const MODULE_NAME = 'getPosition'
export const GET_POSITION = 'GET_POSITION'

// let devicePosition = null

// function successCallback(position) {
//   devicePosition = position.coords
// }
// function errorCalback() {
//   alert('Ative a localização para que o sistema funcione corretamente!')
// }
export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async [GET_POSITION]() {
      // dispatch(`${ENTERPRISE}/${FETCH_ENTERPRISE}`, null, { root: true })
      // const enterprise = JSON.parse(localStorage.getItem(KEY))
      // const tracker_id = localStorage.getItem(KEY_STORE)
      // if (navigator.geolocation && enterprise?.id) {
      //   const { coordinates } = enterprise.geojson.geometry
      //   const { radius } = enterprise.geojson.properties
      //   const resultLogs = []
      //   let i = 0
      //   setInterval(async () => {
      //     navigator.geolocation.getCurrentPosition(
      //       successCallback,
      //       errorCalback
      //     )
      //     if (!devicePosition) {
      //       return
      //     }
      //     const isInEnterprise = isPointWithinRadius(
      //       {
      //         latitude: devicePosition.latitude,
      //         longitude: devicePosition.longitude
      //       },
      //       { latitude: coordinates[1], longitude: coordinates[0] },
      //       radius
      //     )
      //     // TODO Lógica para enviar alertas:

      //     resultLogs.push(isInEnterprise)
      //     // console.log({
      //     //   latitude: devicePosition.latitude,
      //     //   longitude: devicePosition.longitude
      //     // })
      //     if (resultLogs.length >= 2) {
      //       const actualResult = resultLogs[i]
      //       const previousResult = resultLogs[i - 1]
      //       if (previousResult && !actualResult) {
      //         // console.log('#####SAìDA DA EMPRESA##########')
      //         // alert('#####SAìDA DA EMPRESA##########')
      //         await http.post('/alerts/create', {
      //           tracker_id,
      //           date: new Date().toISOString(),
      //           flags: ['OUT_OF_COMPANY']
      //         })
      //       }
      //       if (!previousResult && actualResult) {
      //         // console.log('#####ENTRADA DA EMPRESA##########')
      //         // alert('#####ENTRADA DA EMPRESA##########')
      //         await http.post('/alerts/create', {
      //           tracker_id,
      //           date: new Date().toISOString(),
      //           flags: ['IN_COMPANY']
      //         })
      //       }
      //       if (previousResult && actualResult) {
      //         // console.log('SE MANTÈM DENTRO DA EMPRESA')
      //       }
      //       if (!previousResult && !actualResult) {
      //         // console.log('SE MANTÈM FORA DA EMPRESA')
      //       }
      //     }
      //     i++
      //   }, 5000)
      // } else {
      //   // console.log(
      //   //   'SEM PERMISSÂO DE LOCALIZAÇÃO - OU - SEM EMPRESA CADASTRADA'
      //   // )
      //   if (!navigator.geolocation) {
      //     alert('ATIVE A LOCALIZAÇÃO')
      //   }
      //   if (!enterprise?.id) {
      //     // console.log('CADASTRE A LOCALIZAÇÃO DA EMPRESA')
      //   }
      // }
    }
  },
  getters: {}
}
